jQuery(document).ready(function ($) {
	$(function () {


		/* Tooltips */
		$('[data-toggle="tooltip"]').tooltip();
		$('.dropdown-modal .close').click(function () {
			$('.dropdown-modal .close').parent().toggle();
		});
		/* ------ */
		
		
		/* COOKIES */
		function setCookie(c_name, value, exdays) {
			var exdate = new Date();
			exdate.setDate(exdate.getDate() + exdays);
			var c_value = escape(value) + ((exdays === null) ? "" : "; expires=" + exdate.toUTCString());
			document.cookie = c_name + "=" + c_value + "; path=/";
		}
		function getCookie(cname) {
			var name = cname + "=";
			var decodedCookie = decodeURIComponent(document.cookie);
			var ca = decodedCookie.split(';');
			for (var i = 0; i <ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
					return c.substring(name.length, c.length);
				}
			}
			return "";
		}
		/* ------ */

		/* ACCESSIBILITY WIDGET --------------------------- */

				/* TEXT SIZE */
				function resizeText(multiplier) {
					var fontSize = getCookie("fontSize");
					if (document.body.style.fontSize === "") {
						if (fontSize === ""){					
							document.body.style.fontSize = "1.6em";
						} else {
							document.body.style.fontSize = fontSize;
						}
					}

					if (multiplier === ""){
						multiplier = 0;
					}
					document.body.style.fontSize = parseFloat(document.body.style.fontSize) + (multiplier * 0.2) + "em";
					setCookie("fontSize",document.body.style.fontSize,"7");
					//setTimeout(setHeaderOffset, 500);
				}
				$('.btn-increase-text').click(function () {
					resizeText(1);
				});
				$('.btn-decrease-text').click(function () {
					resizeText(-1);
				});
				resizeText();
				/* --- */

				/* SPEECH SYTHESIS */
				$('.btn-text-to-speech').click(function () {
					return $('.speechSythesis-dropdown').slideToggle('fast'),!1;
				});

				// Browser Compatability Check

				if (!('speechSynthesis' in window)) {
					$('.text-to-speech').hide();
				}
				/*
				if ('speechSynthesis' in window) {
					$('.speechSynthesis-browser-support').addClass('alert alert-success');
					$('.speechSynthesis-browser-support').append('<p>Your browser <strong>supports</strong> speech synthesis.</p>');
				} else {
					$('.text-to-speech').hide();
					$('.speechSynthesis-browser-support').addClass('alert alert-warning');
					$('.speechSynthesis-browser-support').append('<p>Sorry your browser <strong>does not support</strong> speech synthesis.<br>Try this in <a href="https://www.google.co.uk/intl/en/chrome/browser/canary.html">Chrome Canary</a>.</p>');
				}
				*/

				var synth = window.speechSynthesis;

				var inputForm = document.querySelector('form');
				var inputTxt = $('.speakit').text();
				var voiceSelect = document.querySelector('select');

				var pitch = document.querySelector('#pitch');
				var pitchValue = document.querySelector('.pitch-value');
				var rate = document.querySelector('#rate');
				var rateValue = document.querySelector('.rate-value');

				var voices = [];

				function populateVoiceList() {
				  voices = synth.getVoices();
				  var selectedIndex = voiceSelect.selectedIndex < 0 ? 0 : voiceSelect.selectedIndex;
				  voiceSelect.innerHTML = '';
				  for(i = 0; i < voices.length ; i++) {
					var option = document.createElement('option');
					option.textContent = voices[i].name + ' (' + voices[i].lang + ')';

					if(voices[i].default) {
					  option.textContent += ' -- DEFAULT';
					  option.setAttribute('selected', true);
					}

					option.setAttribute('data-lang', voices[i].lang);
					option.setAttribute('data-name', voices[i].name);
					voiceSelect.appendChild(option);
				  }
				  voiceSelect.selectedIndex = selectedIndex;
				}

				populateVoiceList();
				if (speechSynthesis.onvoiceschanged !== undefined) {
				  speechSynthesis.onvoiceschanged = populateVoiceList;
				}

				function speak() {
				  if(inputTxt !== ''){
					var utterThis = new SpeechSynthesisUtterance(inputTxt);
					var selectedOption = voiceSelect.selectedOptions[0].getAttribute('data-name');
					for(i = 0; i < voices.length ; i++) {
					  if(voices[i].name === selectedOption) {
						utterThis.voice = voices[i];
					  }
					}
					utterThis.lang = 'en-AU';
					utterThis.pitch = pitch.value;
					utterThis.rate = rate.value;
					synth.speak(utterThis);
				  }
				}

				$("#speak-pause").hide();
				$("#speak-resume").hide();

				$("#speak-play").click(function () {
					speechSynthesis.cancel();
					//speak();
					$("#speak-pause").show();
					$("#speak-resume").hide();
				});

				$("#speak-pause").click(function () {
					speechSynthesis.pause();
					$("#speak-pause").hide();
					$("#speak-resume").show();
				});

				$("#speak-resume").click(function () {
					speechSynthesis.resume();
					$("#speak-resume").hide();
					$("#speak-pause").show();
				});

				inputForm.onsubmit = function(event) {
				  event.preventDefault();
				  speak();
				  //inputTxt.blur(); // ToDo: Causing TypeError
				};

				pitch.onchange = function() {
				  pitchValue.textContent = pitch.value;
				};

				rate.onchange = function() {
				  rateValue.textContent = rate.value;
				};

				voiceSelect.onchange = function() {
				  //speak();
				};
				/* -------------------------------------------------- */


	});	
});